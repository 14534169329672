import React, { useCallback, useEffect, useState } from "react";
import { Button, Input, Form, Modal, Alert } from "antd";
import { Link, json, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AuthLayout from "./authLayout";
import AuthService from "../../services/auth.service";
import AgentKyc from "../../services/agentkyc.service";
import NotificationService from "../../services/notification.service";

import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Typography, Tabs, Tab } from '@material-ui/core';
import clsx from 'clsx';

import {
  setPermissionList,
  setTotalPermissionList,
} from "../../store/actions/app.action";
import { NAVIGATION_LINKS, OPERATIONS } from "../../config/config";
import { loginDetailsSelector } from "../../store/selectors/app.selector";
import { branchListSelector } from "../../store/selectors/app.selector";
import BranchService from "../../services/branch.service";

const useStyles = makeStyles(theme => ({
  root: {
    color: 'red',
  },

  cardbackground: {
    backgroundColor: '#F0F0F0',
    maxWidth: '450px',

  }
}));




export default function LoginComponent() {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const [sessionMessage, setSessionMessage] = useState("");
  const [anotherSessionVisible, seAnotherSessionVisible] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [form] = Form.useForm();
  const [loginForm] = Form.useForm();


  const [sessionData, setSessionData] = useState(
    "You are already logged in another session. Press OK to login here and end the other session. Press Cancel to not login here and retain the other session."
  );
  const [formValues, setFormValues] = useState([]);
  const loginDetails = useSelector(loginDetailsSelector);
  // const [branchList, setBranchList] = useState([]);



  // const isDifferentBranch = useCallback(() => {
  //   if (loginDetails && loginDetails.user && loginDetails.user.homeBranch && loginDetails.user.currentBranch) {
  //     return loginDetails.user.homeBranch.code !== loginDetails.user.currentBranch.code;
  //   }
  //   return false;
  // }, [loginDetails]);


  useEffect(() => {
    dispatch({ type: "USER_LOGOUT" });
  }, []);

  const getBranchList = useCallback(async () => {
    try {
      const req = {
        isMaintenance: false,
      };
      const response = await BranchService.getBranchList(req);
      if (response.status === 200) {
        // setBranchList(response.data);
      }
    } catch (err) { }
  }, []);


  const getUserList = useCallback(async () => {
    try {

      const response = await AuthService.getAllUserList();
      if (response.status === 200) {
      }
    } catch (err) { }
  }, []);

  /*const postLogin = useCallback(async (values) => {
    try {
      setFormValues(values);
      setLoading(true);
      setMessage("");
      const response = await AuthService.WebportalLogin(values);
      if (response.status === 200) {
        if (
          response.data.newPwdFlag === "Y" ||
          response.data.updatePasswordRequired === true
        ) {
          navigate("/resetPassword");
          return;
        }
        await getBranchSettings(response.data);
        await getBranchList();//Call getBranchList
      }
    } catch (error) {
      setLoading(false);
      if (error.data["hasOtherSession"] == true) {
        seAnotherSessionVisible(true);
        setSessionMessage(error.data["message"]);
      } else {
        setMessage((error && error.data && error.data["message"]) || "");
      }
    }
  }, []);*/



  const postLogin = useCallback(async (values) => {

    const req = {
      isMaintenance: false,
    };
    setLoading(true);
    const sharedkey = await AuthService.generateKey(req);
    if (sharedkey.status === 200) {
      try {

        setFormValues(values);
        setMessage("");
        const response = await AuthService.WebportalLogin(values);
        if (response.status === 200) {
          if (response.data.data.user.isFirstTimeLogin == true) {
            setChangePassword(true);
            setLoading(false)
            setSessionMessage("Change Password");
          }
          else {
            await getBranchSettings(response.data);
            await getBranchList();//Call getBranchList

          }

        }
      } catch (error) {
        console.log("-------------" + JSON.stringify(error))
        setLoading(false);
        if (error.data["hasOtherSession"] == true) {
          seAnotherSessionVisible(true);
          setSessionMessage(error.data["message"]);
        } else {
          setMessage((error && error.data && error.data["message"]) || "");
        }
      }
    } else {
      setLoading(false);
      setMessage("Failed");
    }

  }, []);


  const terminateActiveSesson = useCallback(async () => {
    try {
      setLoading(true);
      setMessage("");
      formValues.logoutOtherSession = true;
      const response = await AuthService.WebportalLogin(formValues);
      if (response.status === 200) {
        seAnotherSessionVisible(false);
        if (response.data.data.user.isFirstTimeLogin == true) {
          setChangePassword(true);
          setSessionMessage("Change Password");

          setLoading(false)
        }
        else {
          await getBranchSettings(response.data);

        }
      }
    } catch (error) {
      setLoading(false);
      setMessage((error && error.data && error.data["message"]) || "");
    }
  }, [formValues]);

  const getBranchSettings = useCallback(async (details) => {
    try {
      const response = await AuthService.getBranchSettings({});
      if (response.status === 200) {
        setLoading(false);
        getCounts(details);
      }
    } catch (err) {
      setLoading(false);
    }
  }, []);



  const getCounts = useCallback(async (details) => {
    try {
      const response = await AuthService.getAllCounts({});
      if (response.status === 200) {
        setLoading(false);
        // getMenus(details, response.data["menus"]);
        getAllMenus(details);
      }
    } catch (err) {
      setLoading(false);
    }
  }, []);

  const onChangePassword = async (values) => {
    try {
      const request = {
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
        otp: 0,
      };
      setLoading(true);
      const response = await AuthService.changePassword(request);
      if (response.status === 200) {
        setLoading(false);
        if (
          response.data["message"] ===
          "Please check the user name or old password"
        ) {
          NotificationService.showErrorMessage(response.data["message"]);
        } else {
          NotificationService.showSuccessMessage(response.data["message"]);
        }
        form.resetFields();
        loginForm.resetFields();
        setChangePassword(false);
      }
    } catch (error) {
      setLoading(false);
      setMessage((error && error.data && error.data["message"]) || "");
      NotificationService.showErrorMessage(error.data.message);
    }
  };




  const getAllMenus = useCallback(
    async (details) => {
      try {
        const response = await AuthService.getAllMenus({});
        if (response.status === 200) {
          setLoading(false);
          let data = response.data;
          const result = [];
          const childMenus = [];
          const totalSA =
            details.data.user.currentBranchPermissions.screensAndActions;

          data.items.forEach((item, index) => {
            if (item.screenId != null) {
              var scr = totalSA[item.screenId];
            }
            const menuItem = {
              id: item.screenId,
              parentMenuId: 0,
              menuName: item.displayName.split(' ')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                .join(' '),
              activeFlag: "Y",
              displayOrder: index,
              defaultPermission: "",
              menukey: item.screenCode || item.displayName.toUpperCase(),
              subMenus: [],
              pathName: item.relativePath,
              isCreate:
                item.screenId != null
                  ? OPERATIONS[scr].indexOf("C") > -1
                    ? true
                    : false
                  : false,
              isRead:
                item.screenId != null
                  ? OPERATIONS[scr].indexOf("R") > -1
                    ? true
                    : false
                  : false,
              isUpdate:
                item.screenId != null
                  ? OPERATIONS[scr].indexOf("U") > -1
                    ? true
                    : false
                  : false,
              isDelete:
                item.screenId != null
                  ? OPERATIONS[scr].indexOf("D") > -1
                    ? true
                    : false
                  : false,
              isAuthorize:
                item.screenId != null
                  ? OPERATIONS[scr].indexOf("A") > -1
                    ? true
                    : false
                  : false,
            };

            if (item.children && item.children.length > 0) {
              item.children.forEach((child, childIndex) => {
                const val = totalSA[child.screenId];
                menuItem.subMenus.push({
                  menuId: child.screenId,
                  menuName: child.displayName.split(' ')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                    .join(' '),
                  parentMenuId: item.screenId,
                  permission: OPERATIONS[val],
                  displayOrder: childIndex,
                  menuKey: child.screenCode,
                  isCreate: OPERATIONS[val].indexOf("C") > -1 ? true : false,
                  isRead: OPERATIONS[val].indexOf("R") > -1 ? true : false,
                  isUpdate: OPERATIONS[val].indexOf("U") > -1 ? true : false,
                  isDelete: OPERATIONS[val].indexOf("D") > -1 ? true : false,
                  isAuthorize: OPERATIONS[val].indexOf("A") > -1 ? true : false,
                  pathName: child.relativePath,
                  subMenus: [],
                });
                childMenus.push({
                  menuId: child.screenId,
                  menuName: child.displayName.split(' ')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                    .join(' '),
                  parentMenuId: item.screenId,
                  permission: OPERATIONS[val],
                  displayOrder: childIndex,
                  menuKey: child.screenCode,
                  isCreate: OPERATIONS[val].indexOf("C") > -1 ? true : false,
                  isRead: OPERATIONS[val].indexOf("R") > -1 ? true : false,
                  isUpdate: OPERATIONS[val].indexOf("U") > -1 ? true : false,
                  isDelete: OPERATIONS[val].indexOf("D") > -1 ? true : false,
                  isAuthorize: OPERATIONS[val].indexOf("A") > -1 ? true : false,
                  pathName: child.relativePath,
                  subMenus: [],
                });
              });
            }
            result.push(menuItem);
          });
          dispatch(
            setTotalPermissionList(childMenus.filter((ele) => ele.menuId))
          );

          dispatch(setPermissionList(result));
          if(details.data.user.customerId==0){
            navigate("/dashboard");
          }
          else{
            navigate("/agencydashboard");

          }
          getAgentDetails(details.data.user.customerId)
          getBranchList();
          getUserList();//Call getBranchList

        }
      } catch (err) {
        setLoading(false);
      }
    },
    [loginDetails]
  );



  const getAgentDetails = useCallback(async (id) => {
    try {
      //   alert(id)
      if (id != 0) {
        const reqParams = {
          isMaintenance: false,
          id: id
        };
        const response = await AgentKyc.getKycDetails(reqParams);
        if (response.status === 200) {
          setLoading(false);
        }

      }

    } catch (err) {
      setLoading(false);
    }
  }, []);

  return (
    <AuthLayout>
      <h5><b>Techverve BANK</b></h5>

      <Form
        form={loginForm}
        name="verifyEmailForm"
        layout="vertical"
        requiredMark={true}
        onFinish={postLogin}
      >
        <Form.Item
          label="Username"
          name="userName"
          rules={[{ required: true, message: "Username is required" }]}
        >
          <Input size="large" placeholder="Username" />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Password is required" }]}
        >
          <Input.Password size="large" type="password" placeholder="Password" />
        </Form.Item>

        <Link to={"/forgot-password"} className="forgot">
          Forgot Password ?
        </Link>

        <Button
          style={{}}
          block
          size="large"
          type="primary"
          htmlType="submit"
          loading={loading}
        >
          Login
        </Button>
      </Form>

      {message.length > 0 && (
        <Alert
          style={{}}
          showIcon={true}
          message={message}
          type="error"
        />
      )}

      <Modal
        closable={false}
        title={sessionMessage}
        open={anotherSessionVisible}
        onOk={terminateActiveSesson}
        onCancel={() => seAnotherSessionVisible(false)}
      >
        <div>
          <span>{sessionData}</span>
        </div>
      </Modal>


      <Modal
        closable={false}
        title={sessionMessage}
        open={changePassword}
        onOk={form.submit}
        onCancel={() => {
          setChangePassword(false)
          form.resetFields();
        }}
      >
        <div>
          <Form
            form={form}
          onFinish={onChangePassword}
            name="ChangePassordForm"
            layout="vertical">
            <Form.Item
              label="Current Password"
              name="oldPassword"
              rules={[
                { required: true, message: "Current Password is required" },
              ]}
            >
              <Input.Password
                maxLength={20}
                size="large"
                placeholder="Current Password"
              />
            </Form.Item>
            <Form.Item
              label="New Password"
              name="newPassword"
              rules={[
               
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("oldPassword") !== value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      "New Password should not same as Current Password"
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                maxLength={20}
                size="large"
                placeholder="New Password"
              />
            </Form.Item>
            <Form.Item
              label="Confirm New Password"
              name="confirmPassword"
              rules={[
                {
                  required: true,
                  message: "Confirm New Password is required",
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject("Passwords do not match!");
                  },
                }),
              ]}
            >
              <Input.Password
                size="large"
                placeholder="Confirm New Password"
                maxLength={20}
              />
            </Form.Item>
          </Form>
          {message.length > 0 && (
            <Alert
              style={{ marginTop: 30 }}
              showIcon={true}
              message={message}
              type="error"
            />
          )}
        </div>
      </Modal>

    </AuthLayout>


  );
};






